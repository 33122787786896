import { useRecoilState, useRecoilValue } from "recoil";
import { apiClient, jwt, serviceState } from "../state/atoms";
import { useApi } from "@blindspot/bspot-api-lib";
import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import * as Sentry from "@sentry/react";

async function parseJwt(token: string) {
  const base64Payload = token.split(".")[1];
  const payload = atob(base64Payload);
  return JSON.parse(payload.toString());
}

export default function ServiceProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [service, setService] = useRecoilState(serviceState);
  const [error, setError] = useState("Something with your account is wrong, please contact support.");
  const api = useRecoilValue(apiClient);
  const token = useRecoilValue(jwt);
  const [data, errWhoami] = useApi(api.get_whoami());
  const [services, errServices] = useApi(api.get_services_list_simple());
  const scope = Sentry.getCurrentScope();

  useEffect(() => {
    if (service === null && services !== undefined) {
      scope.setTag("service.id", services.services[0].id);
      scope.setTag("service.name", services.services[0].name);
      setService(services.services[0].id);
    }
    if (services?.services.filter(s => s.id === service).length === 0) {
      setService(services.services[0].id);
      scope.setTag("service.id", services.services[0].id);
      scope.setTag("service.name", services.services[0].name);
    }
  }, [services]);

  useEffect(() => {
    scope.setUser({ username: data?.username });
  }, [data]);

  useEffect(() => {
    parseJwt(token ?? "").then(jwt => {
      (!jwt.blindspot || jwt.blindspot.length === 0) && setError("You have no assigned services.");
    });
  }, [token]);

  if (errWhoami || errServices) {
    console.log(errWhoami?.msg);
    return (
      <Snackbar className="print-hide" open anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    );
  }

  return service ? <>{children}</> : null;
}
