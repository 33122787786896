import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import TopCard, { CustomCardAction } from "./TopCard";
import { useApi } from "@blindspot/bspot-api-lib";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";
import useService from "../hooks/useService";
import { useTranslation } from "react-i18next";

export default function ConnectionsCard() {
  const api = useRecoilValue(apiClient);
  const { t } = useTranslation("index", { keyPrefix: "cards.peace.connections" });
  const service = useService();
  const [data, err, isLoading] = useApi([api.get_bgp_sessions(), service]);
  const loading = isLoading;

  const established = data?.bgp_connections.filter(connection =>
    connection.session_status.status.includes("Established")
  ).length;

  const warn: number | undefined = data?.bgp_connections.filter(
    connection => !connection.session_status.status.includes("Established")
  ).length;

  return (
    <TopCard
      isLoading={loading}
      status="normal"
      overline={t("title")}
      h5={established !== undefined ? `${established} ${t("count_alive", { count: established })}` : ""}
      dotStatuses={warn ? [{ state: "warn", name: `${warn} connection down` }] : []}
      icon={
        warn ? (
          <WarningAmberOutlinedIcon color="warning" fontSize="large" sx={{ alignSelf: "flex-start" }} />
        ) : (
          <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" sx={{ alignSelf: "flex-start" }} />
        )
      }
    >
      <CustomCardAction linkToIdElement={{ text: t("link"), href: "#connections", color: "primary" }} />
    </TopCard>
  );
}
