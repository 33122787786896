import { Box, SxProps, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TabChangeTimeProps {
  value: "10 MIN" | "HOUR" | "DAY" | "WEEK" | "MONTH";
  sxItem?: SxProps;
  sx?: SxProps;
  onChange: (value: "10 MIN" | "HOUR" | "DAY" | "WEEK" | "MONTH") => void;
}
const TIME: Array<"10 MIN" | "HOUR" | "DAY" | "WEEK" | "MONTH"> = ["10 MIN", "HOUR", "DAY", "WEEK", "MONTH"];

export default function TabChangeTime({ sxItem, onChange, value, sx: sxParent }: TabChangeTimeProps) {
  const { t } = useTranslation("components", { keyPrefix: "tab_change_time" });
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(TIME[newValue]);
  };

  return (
    <Tabs
      sx={{ minHeight: "2.5rem", width: "100%", ...sxParent }}
      value={TIME.findIndex(time => time === value)}
      onChange={handleChange}
      aria-label="basic tabs example"
      className="print-hide"
    >
      <Tab sx={sxItem} label={t("10_min")} />
      <Tab sx={sxItem} label={t("hour")} />
      <Tab sx={sxItem} label={t("day")} />
      <Tab sx={sxItem} label={t("week")} />
      <Tab sx={sxItem} label={t("month")} />
      {/* <Box component={Tab} sx={{ position: "absolute", zIndex: -1000, width: "1px", left: -100 }} /> */}
    </Tabs>
  );
}
