import { Box, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Dot from "./Dot";
import { alpha } from "@mui/material";
import ButtonChangeStatus from "./Buttons/ButtonChangeStatus";
import useUnderAttack from "../hooks/useUnderAttack";
import useService from "../hooks/useService";
import { useRecoilValue } from "recoil";
import { useApi } from "@blindspot/bspot-api-lib";
import { apiClient } from "../state/atoms";
import useReportError from "../hooks/useReportError";
import { IPv, NetworkUnit, Period } from "@blindspot/bspot-api-lib";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { formatPackets } from "../utils/formatPackets";
import CrosshairPlugin from "chartjs-plugin-crosshair";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { Interval } from "./TrafficGraph";
import { getTimeFrame } from "../utils/getTimeFrame";
import { formatBits } from "../utils/formatBits";

export type Time = "10 MIN" | "HOUR" | "DAY" | "WEEK" | "MONTH";

export function timeToInterval(time: Time): Period {
  return time === "10 MIN"
    ? "minute"
    : time === "HOUR"
    ? "hour"
    : time === "DAY"
    ? "day"
    : time === "WEEK"
    ? "week"
    : "month";
}

interface TrafficGraphProps {
  IPversion: IPv;
  time: Time;
  interval: Interval | undefined;
  setInterval: Dispatch<SetStateAction<Interval | undefined>>;
}

export default function TrafficGraphLine({ IPversion, time, interval, setInterval }: TrafficGraphProps) {
  const theme = useTheme();
  const { t } = useTranslation("index", { keyPrefix: "traffic_graph" });
  const underAttack: boolean = useUnderAttack();
  const { i18n } = useTranslation();
  //const chartRef = useRef<ChartJSOrUndefined<"line", number[], string>>(null);
  const chartRef = useRef<ChartJSOrUndefined<"line", number[], string>>(null);

  const [units, setUnits] = useState<NetworkUnit>("l3bps");

  useEffect(() => {
    //setInterval(undefined);
    //chartRef.current && console.log(chartRef.current.options.plugins?.crosshair);
    //(CrosshairPlugin as any).resetZoom(chartRef);
    chartRef.current && (CrosshairPlugin as any).resetZoom(chartRef.current);
  }, [time, units, IPversion]);

  const service = useService();
  const api = useRecoilValue(apiClient);
  const [traffic, error, loading] = interval
    ? useApi(
        [
          api.get_traffic(),
          { service, ipv: IPversion, units, interval: "custom", start: interval.from, end: interval.to },
        ],
        { stale: true }
      )
    : useApi([api.get_traffic(), { service, ipv: IPversion, units, interval: timeToInterval(time) }], { stale: true });
  const [serviceData, errorService] = useApi([api.get_service(), service]);
  useReportError([errorService, error], ["get_tenant", "get_traffic"]);

  if (error) {
    if (error?.status_code === 204 && traffic === undefined) {
      return (
        <CardContent sx={{ display: "grid", placeItems: "center", height: "100%" }}>
          <Typography variant="subtitle1">{t("no_data")}</Typography>
        </CardContent>
      );
    } else if (traffic === undefined) {
      return (
        <CardContent sx={{ display: "grid", placeItems: "center", height: "100%" }}>
          <Typography variant="subtitle1">{t("failed_data_load")}</Typography>
        </CardContent>
      );
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "31rem",
          width: "100%",
          padding: "0.5rem",
        }}
      >
        <Box sx={{ display: "flex", alignItem: "center", margin: "0.5rem 1rem", justifyContent: "right" }}>
          <ButtonChangeStatus
            sx={{ height: "2rem" }}
            onChange={e => setUnits(e === "bps" ? "l3bps" : "pps")}
            currentName={units === "l3bps" ? "bps" : "pps"}
            leftName={"bps"}
            rightName={"pps"}
          />
        </Box>
        {(traffic && (
          <>
            <Box
              sx={{ position: "relative", display: "flex", height: "100%", flexDirection: "column" }}
              data-testid={"trafficGraph"}
            >
              <Box
                sx={{
                  position: "absolute",
                  display: loading ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </Box>
              <Line
                ref={chartRef}
                data={{
                  labels: traffic[0].labels.map(label => {
                    return new Date(traffic[0].labels[traffic[0].labels.length - 1]).getTime() -
                      new Date(traffic[0].labels[0]).getTime() <
                      24 * 60 * 60 * 1000
                      ? new Date(label).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
                      : new Date(label).toLocaleDateString("hsb-DE");
                  }),
                  datasets: [
                    {
                      label: "attack",
                      data: traffic[0].data.map(x => x / (units === "l3bps" ? 1_000_000 : 1_000)),
                      borderColor: theme.palette.error.main,
                      pointBorderColor: theme.palette.background.paper,
                      pointBackgroundColor: theme.palette.error.main,
                      pointRadius: underAttack ? [0] : [],
                      showLine: true,
                      cubicInterpolationMode: "monotone",
                      fill: true,
                      gradient: {
                        backgroundColor: {
                          axis: "y",
                          colors: {
                            0: alpha(theme.palette.background.paper, 0.1),
                            700: alpha(theme.palette.error.main, 0.25),
                          },
                        },
                      },
                    },
                    {
                      label: "clean",
                      data: traffic[1].data.map(x => x / (units === "l3bps" ? 1_000_000 : 1_000)),
                      borderColor: theme.palette.success.main,
                      fill: true,
                      cubicInterpolationMode: "default",
                      pointRadius: 0,
                      pointBackgroundColor: theme.palette.success.main,
                      //@ts-expect-error valid prop
                      interpolate: true,

                      gradient: {
                        backgroundColor: {
                          axis: "y",
                          colors: {
                            0: alpha(theme.palette.background.paper, 0.1),
                            100: alpha(theme.palette.success.main, 0.2),
                          },
                        },
                      },
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  interaction: {
                    mode: "index",
                    intersect: false,
                  },
                  plugins: {
                    tooltip: {
                      enabled: true,
                      callbacks: {
                        label: function (context) {
                          let label = context.dataset.label || "";

                          if (label) {
                            label += ": ";
                          }
                          if (context.parsed.y !== null) {
                            if (units === "l3bps") {
                              label += formatBits(context.parsed.y * 1_000_000, i18n.language, true) + "ps";
                            } else {
                              label += formatPackets(context.parsed.y * 1_000, i18n.language, true) + "ps";
                            }
                          }
                          return label;
                        },
                      },
                    },
                    crosshair: {
                      line: {
                        color: theme.palette.text.disabled,
                        width: 1,
                      },
                      sync: {
                        enabled: false,
                      },
                      zoom: {
                        enabled: true,
                        zoomButtonClass: "zoom_btn",
                        zoomboxBackgroundColor: alpha(theme.palette.text.disabled, 0.1),
                        zoomboxBorderColor: "transparent",
                      },
                      callbacks: {
                        afterZoom: () =>
                          function (start: number, end: number) {
                            setInterval({
                              from: new Date(traffic[0].labels[start]),
                              to: new Date(traffic[0].labels[end]),
                            });
                          },
                        resetButton: () =>
                          function () {
                            setInterval(getTimeFrame(time));
                          },
                      },
                    },
                    annotation: {
                      annotations: {
                        bandwidth: {
                          type: "line",
                          display: units !== "pps",
                          borderColor: theme.palette.text.disabled,
                          borderWidth: 1,
                          borderDash: [15, 10],
                          label: {
                            backgroundColor: "transparent",
                            color: theme.palette.text.disabled,
                            content: "Your bandwidth limit",
                            position: "start",
                            yAdjust: -10,
                            display: true,
                            font: {
                              weight: "normal",
                              size: 12,
                              family: "DM Sans",
                            },
                          },
                          scaleID: "y",
                          value: serviceData ? serviceData.sp_max_clean_traffic : undefined,
                        },
                      },
                    },
                    legend: {
                      display: false,
                    },
                    datalabels: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        tickColor: "transparent",
                        color: theme.palette.divider,
                        borderColor: theme.palette.divider,
                      },
                      ticks: {
                        color: theme.palette.text.secondary,
                        autoSkip: true,
                        maxTicksLimit: 6,
                      },
                    },
                    y: {
                      min: 0,
                      beginAtZero: true,
                      grid: {
                        drawBorder: false,
                        tickColor: "transparent",
                        color: theme.palette.divider,
                        borderColor: theme.palette.divider,
                      },
                      ticks: {
                        color: theme.palette.text.secondary,
                        callback: (label, tick, labels) =>
                          labels.length - 1 == tick
                            ? units === "l3bps"
                              ? "Mbps"
                              : "Kpps"
                            : label.toLocaleString(i18n.language),
                      },
                    },
                  },
                }}
                plugins={[
                  //@ts-expect-error valid registration
                  CrosshairPlugin,
                  {
                    id: "chartAreaBorder",
                    beforeDraw(chart) {
                      const {
                        ctx,
                        chartArea: { top, right, bottom },
                      } = chart;
                      ctx.beginPath();
                      ctx.moveTo(right - 0.5, top + 0.5);
                      ctx.lineTo(right - 0.5, bottom - 0.5);
                      ctx.strokeStyle = theme.palette.divider;
                      ctx.stroke();
                    },
                  },
                ]}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: "center",
                  paddingTop: "1rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <Dot status={"ok"} />
                  <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                    {t("clean_traffic")}:{" "}
                    {units === "l3bps"
                      ? formatBits(traffic[1].average, i18n.language, true)
                      : formatPackets(traffic[1].average, i18n.language, true)}
                    ps
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <Dot status={"err"} />
                  <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                    {t("attack")}:{" "}
                    {units === "l3bps"
                      ? formatBits(traffic[0].average, i18n.language, true)
                      : formatPackets(traffic[0].average, i18n.language, true)}
                    ps
                  </Typography>
                </Box>
              </Box>
              <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                {t("total")}:{" "}
                {units === "l3bps"
                  ? formatBits(traffic[2].average, i18n.language, true)
                  : formatPackets(traffic[2].average, i18n.language, true)}
                ps
              </Typography>
            </Box>
          </>
        )) || (
          <Box sx={{ height: "100%", display: "flex", justifyContent: "center" }}>
            <Box sx={{ height: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <CircularProgress />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
