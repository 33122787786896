export const getTimeFrame = (timeFrame: "10 MIN" | "HOUR" | "DAY" | "WEEK" | "MONTH") => {
  const getShiftedInterval = (minutes: number) => {
    const to = new Date();
    to.setSeconds(to.getSeconds() - 10);

    const from = new Date();
    from.setMinutes(to.getMinutes() - minutes);
    from.setSeconds(to.getSeconds());

    return { to, from };
  };

  switch (timeFrame) {
    case "10 MIN":
      return getShiftedInterval(10);
    case "HOUR":
      return getShiftedInterval(60);
    case "DAY":
      return getShiftedInterval(1440);
    case "WEEK":
      return getShiftedInterval(10080);
    case "MONTH":
      return getShiftedInterval(40320);
  }
};
