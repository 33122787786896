import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import TabChangeTime from "./TabChangeTime";
import BarGraphCard from "./BarGraphCard";
import useUnderAttack from "../hooks/useUnderAttack";
import AnchorGap from "./AnchorGap";
import { IPv, useApi } from "@blindspot/bspot-api-lib";
import TrafficGraphLine, { timeToInterval, Time } from "./TrafficGraphLine";
import { useRecoilValue } from "recoil";
import useReportError from "../hooks/useReportError";
import useService from "../hooks/useService";
import { apiClient } from "../state/atoms";
import { quickSort } from "../utils/sort";
import toFlagEmoji from "../utils/toFlagEmoji";
import countryCodeToCountry from "../utils/countryCodeToCountry";
import { useTranslation } from "react-i18next";
import Pagination from "./Pagination";
import ButtonIPSwitch from "./Buttons/ButtonIPSwitch";
import { getTimeFrame } from "../utils/getTimeFrame";

export interface Interval {
  from: Date;
  to: Date;
}

function TrafficGraph() {
  const theme = useTheme();
  const { t } = useTranslation("index", { keyPrefix: "traffic" });
  const underAttack: boolean = useUnderAttack();
  const service = useService();

  const [IPversion, setIPversion] = useState<IPv>("all");
  const [time, setTime] = useState<Time>("10 MIN");
  const [interval, setInterval] = useState<Interval | undefined>(getTimeFrame(time));

  return (
    <>
      <AnchorGap idTarget="destination_ip" height={70} />
      <Card
        sx={{ display: "flex", flexDirection: "column", height: "100%", border: 1, borderColor: theme.palette.divider }}
      >
        <CardHeader
          sx={{ borderBottom: 1, borderColor: theme.palette.divider }}
          title={<Typography variant="h6">{t("title")}</Typography>}
          action={
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <TabChangeTime onChange={setTime} value={time} sxItem={{ minHeight: "2.5rem", minWidth: "4.5rem" }} />
              <ButtonIPSwitch
                sx={{ paddingLeft: "1rem" }}
                onChange={setIPversion}
                currentName={IPversion}
                leftName={"ipv4"}
                rightName={"ipv6"}
                all={"all"}
              />
            </Box>
          }
        />
        <CardContent sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "stretch" }}>
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <TrafficGraphLine IPversion={IPversion} time={time} interval={interval} setInterval={setInterval} />
            </Grid>
            <Grid item xs={7}>
              <Box
                sx={{
                  margin: "0.5rem",
                }}
                data-testid={"ipAdressTable"}
              >
                <Grid container spacing={2}>
                  <Grid xs={6} item sx={{ display: "flex", flexDirection: "column" }}>
                    <DestinationIPAddresses
                      service={service}
                      ipv={IPversion}
                      time={time}
                      underAttack={underAttack}
                      interval={interval}
                    />
                    <ProtocolsUsedInAttacks
                      service={service}
                      ipv={IPversion}
                      time={time}
                      underAttack={underAttack}
                      interval={interval}
                    />
                  </Grid>
                  <Grid xs={6} item sx={{ height: "100%" }}>
                    <SourcesOfTraffic
                      service={service}
                      ipv={IPversion}
                      time={time}
                      underAttack={underAttack}
                      interval={interval}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

function DestinationIPAddresses({
  service,
  ipv,
  time,
  underAttack,
  interval,
}: {
  service: number;
  ipv: IPv;
  time: Time;
  underAttack: boolean;
  interval: Interval | undefined;
}) {
  const theme = useTheme();
  const { t } = useTranslation("index", { keyPrefix: "traffic" });
  const [page, setPage] = useState(0);

  const api = useRecoilValue(apiClient);
  const [traffic, error] = interval
    ? useApi([
        api.get_traffic_destinations(),
        { service, ipv, interval: "custom", start: interval.from, end: interval.to },
      ])
    : useApi([api.get_traffic_destinations(), { service, ipv, interval: timeToInterval(time) }]);
  useReportError(error, "get_traffic_destinations");

  const data = useMemo(() => {
    if (traffic === undefined) return undefined;
    const other = traffic.data["Other"];

    const sorted = quickSort(
      Object.entries(traffic.data)
        .filter(([key]) => key !== "Other")
        .map(([key, value]) => ({ title: key, percent: value })),
      (a, b) => a.percent > b.percent
    );
    other && sorted.push({ title: "Other", percent: other });
    return sorted;
  }, [traffic]);

  if (error || data?.length == 0) {
    return (
      <BarGraphCard.Error title={t("destination")} borderless={true} errorStatusCode={error?.status_code || 204} />
    );
  }

  return (
    (data && (
      <BarGraphCard
        sxAction={{ padding: "0px" }}
        title={t("destination")}
        rowsPerPage={2}
        minHeight="112px"
        rows={data.slice(page * 2, page * 2 + 2)}
        colorBar={underAttack ? theme.palette.error.main : theme.palette.primary.main}
        borderless={true}
      >
        <Pagination
          sx={{ "@media print": { display: "none" } }}
          rowsPerPage={2}
          pageCount={data.length}
          page={page}
          setPage={setPage}
        />
      </BarGraphCard>
    )) || <BarGraphCard.Loading title={t("destination")} borderless={true} rowsPerPage={2} />
  );
}

function ProtocolsUsedInAttacks({
  service,
  ipv,
  time,
  underAttack,
  interval,
}: {
  service: number;
  ipv: IPv;
  time: Time;
  underAttack: boolean;
  interval: Interval | undefined;
}) {
  const theme = useTheme();
  const { t } = useTranslation("index", { keyPrefix: "traffic" });
  const [page, setPage] = useState(0);

  const api = useRecoilValue(apiClient);
  const [protocols, error] = interval
    ? useApi([
        api.get_traffic_protocols(),
        { service, ipv, interval: "custom", start: interval.from, end: interval.to },
      ])
    : useApi([api.get_traffic_protocols(), { service, ipv, interval: timeToInterval(time) }]);
  useReportError(error, "get_traffic_protocols");

  const data = useMemo(() => {
    if (protocols === undefined) return undefined;
    const other = protocols.data["Other"];

    const sorted = quickSort(
      Object.entries(protocols.data)
        .filter(([key]) => key !== "Other")
        .map(([key, value]) => ({ title: key, percent: value })),
      (a, b) => a.percent > b.percent
    );

    other && sorted.push({ title: "Other", percent: other });
    return sorted;
  }, [protocols]);

  if (error || data?.length == 0) {
    return <BarGraphCard.Error title={t("protocols")} borderless={true} errorStatusCode={error?.status_code || 204} />;
  }

  return (
    (data && (
      <BarGraphCard
        sxAction={{ padding: "0px" }}
        title={t("protocols")}
        rowsPerPage={2}
        minHeight="112px"
        rows={data.slice(page * 2, page * 2 + 2)}
        colorBar={underAttack ? theme.palette.error.main : theme.palette.primary.main}
        borderless={true}
      >
        <Pagination
          sx={{ "@media print": { display: "none" } }}
          rowsPerPage={2}
          pageCount={data.length}
          page={page}
          setPage={setPage}
        />
      </BarGraphCard>
    )) || <BarGraphCard.Loading title={t("protocols")} borderless={true} rowsPerPage={2} />
  );
}

function SourcesOfTraffic({
  service,
  ipv,
  time,
  underAttack,
  interval,
}: {
  service: number;
  ipv: IPv;
  time: Time;
  underAttack: boolean;
  interval: Interval | undefined;
}) {
  const theme = useTheme();
  const { t } = useTranslation("index", { keyPrefix: "traffic" });
  const [page, setPage] = useState(0);

  const api = useRecoilValue(apiClient);
  const [destinations, error] = interval
    ? useApi([
        api.get_traffic_countries(),
        { service, ipv, interval: "custom", start: interval.from, end: interval.to },
      ])
    : useApi([api.get_traffic_countries(), { service, ipv, interval: timeToInterval(time) }]);
  useReportError(error, "get_traffic_countries");

  const data = useMemo(() => {
    if (destinations === undefined) return undefined;
    const other = destinations.data["Other"];

    const sorted = quickSort(
      Object.entries(destinations.data)
        .filter(([key]) => key !== "Other")
        .map(([key, value]) => ({
          title: key === "--" ? t("graphs.countries_unknown") : `${toFlagEmoji(key)} ${countryCodeToCountry(key)}`,
          percent: value,
        })),
      (a, b) => a.percent > b.percent
    );
    other && sorted.push({ title: "Other", percent: other });
    return sorted;
  }, [destinations]);

  if (error || data?.length == 0) {
    return <BarGraphCard.Error title={t("source")} borderless={true} errorStatusCode={error?.status_code || 204} />;
  }

  return (
    (data && (
      <BarGraphCard
        title={t("source")}
        sxAction={{ padding: "0px" }}
        rowsPerPage={data.length}
        rows={data.slice(page * 6, page * 6 + 6)}
        minHeight="355px"
        colorBar={underAttack ? theme.palette.error.main : theme.palette.primary.main}
        borderless={true}
      >
        <Pagination
          sx={{ "@media print": { display: "none" } }}
          rowsPerPage={6}
          pageCount={data.length}
          page={page}
          setPage={setPage}
        />
      </BarGraphCard>
    )) || <BarGraphCard.Loading title={t("source")} borderless={true} rowsPerPage={6} />
  );
}

export default memo(TrafficGraph);
