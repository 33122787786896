export const REDIRECT_URI = (import.meta.env.VITE_REDIRECT_URI || "http://localhost:3000") + location.pathname;
export const CLIENT_ID = import.meta.env.VITE_CLIENT_ID || "bspot-api-gateway-lab-a9f7-b0684f10fd40";
export const API_BASE_URI = import.meta.env.VITE_API_BASE_URI || "https://api.dev.bspot.lab.prz";
//export const API_BASE_URI = import.meta.env.VITE_API_BASE_URI || "http://localhost:8800";

export const AUTH_URI = import.meta.env.VITE_AUTH_URI || "https://auth0.blindspot.cloud";
export const AUTH_REALM = import.meta.env.VITE_AUTH_REALM || "BlindSpot";

export const RELEASE = import.meta.env.VITE_RELEASE;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
