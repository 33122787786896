import { Line } from "react-chartjs-2";

interface LittleCardChartProps {
  data: number[];
  color: string;
}

export default function LittleCardChart({ data, color }: LittleCardChartProps) {
  return (
    <Line
      datasetIdKey="smallGraph"
      data={{
        labels: [...Array(data.length)].map(_ => ""),
        datasets: [
          {
            data: data,
            fill: false,
            borderColor: color,
            tension: 0.4,
          },
        ],
      }}
      options={{
        elements: {
          point: {
            radius: 0,
          },
        },
        events: [],
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            formatter: () => {
              return "";
            },
          },
        },
        showLine: true,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
      }}
    />
  );
}
