import { useTranslation } from "react-i18next";
import TopCard from "./TopCard";
import { Attack, useApi } from "@blindspot/bspot-api-lib";
import { formatPackets } from "../utils/formatPackets";
import { formatBits } from "../utils/formatBits";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";
import useService from "../hooks/useService";

interface ConnectionsErrCardProps {
  attacks: Attack[];
}

export default function ConnectionsErrCard(_: ConnectionsErrCardProps) {
  const { t, i18n } = useTranslation("index", { keyPrefix: "cards" });

  const api = useRecoilValue(apiClient);
  const service = useService();
  const [bits, bitsErr, bitsLoading] = useApi([
    api.get_traffic(),
    { service, ipv: "all", units: "l3bps", interval: "minute" },
  ]);
  const [packets, packetsErr, packetsLoading] = useApi([
    api.get_traffic(),
    { service, ipv: "all", units: "pps", interval: "minute" },
  ]);

  const loading = bitsLoading && packetsLoading;
  const bs = bits?.find(d => d.type === "attack")?.max;
  const ps = packets?.find(d => d.type === "attack")?.max;

  return (
    <TopCard
      isLoading={loading}
      status="attack"
      overline={t("under_attack.peak_bandwidth.title")}
      h5={
        bs !== undefined && ps !== undefined
          ? [formatBits(bs, i18n.language, true) + "ps", formatPackets(ps, i18n.language, true) + "ps"]
          : []
      }
    >
      <></>
    </TopCard>
  );
}
